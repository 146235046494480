import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { datafluxRum } from '@cloudcare/browser-rum';
import App from './App';
import appConfig from './utils/config';
import 'antd/dist/reset.css'; // 确保 antd 样式生效
import './index.css';

if (process.env.REACT_APP_ENV !== 'development') {
  // 使用 guance 云面板
  // 工作空间 test/prod -> 用户访问监测 -> 应用 alalab-admin -> 分析看板
  // 地址：
  // https://us1-console.guance.com/rum/list
  datafluxRum.init({
    applicationId: appConfig['applicationId'],
    clientToken: appConfig['clientToken'],
    env: appConfig['env'],
    allowedTracingUrls: [appConfig['BASE_URL']],
    version: '1.0.0',
    service: 'browser',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 70,
    trackUserInteractions: true,
    site: 'https://rum-openway.guance.one'
  });
  datafluxRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
